import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';

export const apiGet = async (url, options = {}, getAccessTokenSilently) => {

    try {
        const { audience, scope, ignoreCache, ...fetchOptions } = options;

        const accessToken = await getAccessTokenSilently({
            authorizationParams: { audience, scope }
        });

        return await axios.get(url, {
            ...fetchOptions,
            headers: {
                ...fetchOptions.headers,
                // Add the Authorization header to the existing headers
                Authorization: `Bearer ${accessToken}`,
            },
        }).then(response => {
            if (response.status >= 200 && response.status < 300) {
                return response.data;
            }

            throw new Error(`Error with data request: ${response.status}`);
        })


    } catch (error) {
        throw error;
    }

};
