

import React from "react";
import { Auth0Provider } from '@auth0/auth0-react';
import { navigate } from 'gatsby';
import { LanguageRegionContextProvider } from "./src/context-providers/language-region-context";
import { DatabaseContextProvider } from "./src/context-providers/database-context";

export const onServiceWorkerUpdateReady = () => window.location.reload();

export const onRouteUpdate = loc => {
    const { state } = loc.location;
    if (state && state.refocusId) {
        const elem = document.getElementById(state.refocusId);
        if (elem) {
            elem.focus();
        }
    }
};

const onRedirectCallback = (appState) => {
    // Use Gatsby's navigate method to replace the url
    navigate(appState?.returnTo || '/dashboard/servers', { replace: true });
};

export const wrapRootElement = ({ element }) => {

    return (
        <Auth0Provider // https://auth0.github.io/auth0-react/interfaces/Auth0ProviderOptions.html
            domain={process.env.GATSBY_AUTH0_DOMAIN}
            clientId={process.env.GATSBY_AUTH0_CLIENT_ID}
            onRedirectCallback={onRedirectCallback}
            authorizationParams={{ // https://auth0.github.io/auth0-react/interfaces/AuthorizationParams.html
                connection: 'Discord-oAuth', // Skip Auth0 universal login and go straight to Discord login
                audience: `${process.env.GATSBY_AUTH0_AUDIENCE}`, // The identifier for your API
                scope: "read:org post:org", // Declares the scopes that your app will use
                redirectUri: `${window.location.origin}/dashboard/servers`, // Where to redirect to after login
            }}
        >
            <LanguageRegionContextProvider>
                <DatabaseContextProvider>
                    {element}
                </DatabaseContextProvider>
            </LanguageRegionContextProvider>
        </Auth0Provider>
    );
};

