import React, { useState, useContext, useEffect, createContext } from "react";
import { useAuth0 } from '@auth0/auth0-react';
import { apiGet } from '../helpers/api-get';

const setInLocalStorage = (key, content) => {
    localStorage.setItem(key, JSON.stringify(content));
}

const fetchGuildsData = async ({ user, guildIds, getAccessTokenSilently }) => {
    // Get guilds from DB
    const params = new URLSearchParams({ command: "orgs" });
    guildIds?.forEach((guildId) => {
        params.append('guildIds', guildId);
    });
    const url = `${process.env.GATSBY_AUTH0_AUDIENCE}?${params}`;
    const options = {
        "method": "GET",
        "audience": process.env.GATSBY_AUTH0_AUDIENCE,
        "scope": "read:org",
        "ignoreCache": true
    };

    try {
        const guildsFromDB = await apiGet(url, options, getAccessTokenSilently);
        const data = guildsFromDB?.map(e => e);

        if (data) {
            // merge user.guilds and guildsFromDB
            const guilds = user?.guilds?.map((guild) => {
                const guildFromDB = data?.find((g) => g?.orgId === guild.id);
                return {
                    ...guild,
                    data: guildFromDB
                }
            });

            return guilds;
        }
    } catch (error) {
        throw error;
    }
}

const fetchCompetitionsData = async ({ guildIds, getAccessTokenSilently }) => {
    console.log('guildIds in fetchCompetitionsData', guildIds);

    // Get competitions from DB
    const params = new URLSearchParams({ command: "competitions" });
    guildIds?.forEach((competitionId) => {
        params.append('guildIds', competitionId);
    });
    const compTypes = ['tournament', 'league', 'championship'];
    compTypes?.forEach((compType) => {
        params.append('compTypes', compType);
    });
    const url = `${process.env.GATSBY_AUTH0_AUDIENCE}?${params}`;
    const options = {
        "method": "GET",
        "audience": process.env.GATSBY_AUTH0_AUDIENCE,
        "scope": "read:org",
        "ignoreCache": true
    };

    try {
        const data = await apiGet(url, options, getAccessTokenSilently);
        return data;

    } catch (error) {
        throw error;
    }
}

const defaultContext = {
    guildsContext: {},
    updateGuildsContext: () => { },
    refreshGuildsContext: () => { },
    guildIdsContext: {},
    updateGuildIdsContext: () => { },
    refreshGuildIdsContext: () => { },
    competitionsContext: {},
    updateCompetitionsContext: () => { },
    refreshCompetitionsContext: () => { },
};

export const DatabaseContext = createContext(defaultContext);

const Provider = (props) => {
    const [guildsContext, setGuildsContext] = useState({ data: null, loading: true, error: null });
    const [guildsRefreshIndex, setGuildsRefreshIndex] = useState(0);
    const [guildIdsContext, setGuildIdsContext] = useState({ data: null, loading: true, error: null });
    const [guildIdsRefreshIndex, setGuildIdsRefreshIndex] = useState(0);
    const [competitionsContext, setCompetitionsContext] = useState({ data: null, loading: true, error: null });
    const [competitionsRefreshIndex, setCompetitionsRefreshIndex] = useState({});

    const { user, getAccessTokenSilently } = useAuth0();

    // Fetch guild ids from DB when guildIdsContext is loaded
    useEffect(() => {
        const key = 'guildIds';
        const refreshIndex = guildIdsRefreshIndex;
        const setContext = setGuildIdsContext;

        // if refresh index is 0, then try to get data from localstorage
        if (refreshIndex === 0) {
            const data = JSON.parse(localStorage.getItem(key));
            if (data?.length > 0) {
                setContext({ data: data, loading: false, error: null });

                return;
            }
        }

        if (
            (!guildIdsContext?.data || guildIdsContext?.data?.length <= 0) &&
            user?.guilds?.length > 0
        ) {

            const data = user?.guilds?.map((guild) => guild.id);

            // guildsFromDB.refresh();
            setContext({ data: data, loading: false });
            setInLocalStorage(key, data);
            // trigger guildsContext refresh
            setGuildsRefreshIndex(guildsRefreshIndex + 1);
        }

        return () => {
            // this gets called when the component unmounts
        };
    }, [guildIdsRefreshIndex, user?.guilds?.length]);


    // Fetch guilds from DB when guildIdsContext is loaded
    useEffect(() => {
        (async () => {
            const key = 'guilds';
            const refreshIndex = guildsRefreshIndex;
            const setContext = setGuildsContext;

            if (refreshIndex === 0) {
                const data = JSON.parse(localStorage.getItem(key));
                if (data?.length > 0) {
                    setContext({ data: data, loading: false, error: null });

                    return;
                }
            }

            if (
                (!guildsContext?.data || guildsContext?.data?.length <= 0) &&
                guildIdsContext?.data?.length > 0
            ) {
                const guildIds = guildIdsContext?.data;

                const data = await fetchGuildsData({ user, guildIds, getAccessTokenSilently })
                    .catch((error) => {
                        setContext({ data: null, loading: false, error: error });
                    });

                if (data?.length > 0) {
                    setContext({ data: data, loading: false, error: null });
                    setInLocalStorage(key, data);

                    return;
                }
            }
        })();
    }, [guildsRefreshIndex]);


    // Fetch competitions from DB when competitionsRefreshIndex is updated
    useEffect(() => {
        (async () => {
            const refreshIndex = competitionsRefreshIndex;
            const guildIds = [competitionsRefreshIndex?.lastGuildIdRefreshed];
            const setContext = setCompetitionsContext;

            if (!guildIds?.length > 0) {
                return;
            }

            // If refreshIndex is 0, then try to get data from localstorage
            Promise.all(guildIds.map(async (guildId) => {
                if (refreshIndex?.[guildId] === 0 || !refreshIndex?.[guildId]) {
                    const competitionsData = JSON.parse(localStorage.getItem(`competitions${guildId}`));

                    if (competitionsData?.length > 0) {
                        setContext({ data: { [guildId]: competitionsData }, loading: false, error: null });
                        return;
                    }
                }

                return guildId;

            })).then(async (guildIdsToRefresh) => {
                const cleanedGuildIds = guildIdsToRefresh.filter(e => e);
                console.log('cleanedGuildIds', cleanedGuildIds);

                const data = await fetchCompetitionsData({ guildIds: cleanedGuildIds, getAccessTokenSilently })
                    .catch((error) => {
                        setContext({ data: null, loading: false, error: error });
                    });

                if (data) {
                    setContext({ data: data, loading: false, error: null });

                    cleanedGuildIds.forEach((guildId) => {
                        setInLocalStorage(`competitions${guildId}`, data?.[guildId]);
                    });
                }
            });
        })();

        return () => {
            // this gets called when the component unmounts
        };
    }, [competitionsRefreshIndex]);

    return (
        <DatabaseContext.Provider
            value={{
                guildsContext,
                updateGuildsContext: (newContext) => setGuildsContext((previousContext) => {
                    return {
                        ...previousContext,
                        data: {
                            ...previousContext.data,
                            ...newContext.data
                        },
                        loading: newContext.loading || false,
                        error: newContext.error || null
                    }

                }),
                refreshGuildsContext: () => setGuildsRefreshIndex(guildsRefreshIndex + 1),
                guildIdsContext,
                updateGuildIdsContext: (newContext) => setGuildIdsContext(newContext),
                refreshGuildIdsContext: () => setGuildIdsRefreshIndex(guildIdsRefreshIndex + 1),
                competitionsContext,
                updateCompetitionsContext: (newContext) => setCompetitionsContext((previousContext) => {
                    return {
                        ...previousContext,
                        data: {
                            ...previousContext.data,
                            ...newContext.data
                        },
                        loading: newContext.loading || false,
                        error: newContext.error || null
                    }

                }),
                refreshCompetitionsContext: (guildId) => {
                    console.log('refreshCompetitionsContext called', guildId);

                    const currentRefreshIndex = competitionsRefreshIndex?.[guildId] || 0;
                    const newRefreshIndex = currentRefreshIndex + 1;
                    setCompetitionsRefreshIndex(previousRefreshIndex => {
                        return {
                            ...previousRefreshIndex,
                            [guildId]: newRefreshIndex,
                            lastGuildIdRefreshed: guildId
                        }
                    })
                },
            }}
        >
            {props.children}
        </DatabaseContext.Provider>
    );
};

export const DatabaseContextProvider = (props) => {
    return (
        <Provider>{props.children}</Provider>
    );
};

export const useDatabase = () => {
    const context = useContext(DatabaseContext);
    if (context === undefined) {
        throw new Error('useDatabase must be used within a DatabaseContextProvider');
    }
    return context;
};

